import { gql } from '@apollo/client';

export const fetchRepresentativesQuery = gql`
  query getRepresentatives($districtId: String!) {
    representatives(districtId: $districtId) {
      id
      district
      representativeType
      representativeInfo {
        id
        department
        name
        title
        phone
        fax
        email
      }
    }
  }
`;
