/* eslint-disable @typescript-eslint/no-explicit-any */

import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useLazyQuery } from '@apollo/client';
import Button from '@rotaryintl/harris-button';
import Select from '@rotaryintl/harris-form-basic/dist/SelectField/Select';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { isEmpty } from 'lodash';

import Loading from '@components/Loading';

import CDSFinancialCard from './CDSFinancialCard';

import { useFetchAllDistricts } from '@repositories/districts';
import { fetchRepresentativesQuery } from '@repositories/representatives';

import { useTranslation } from '@external/react-i18next';

import { CDSFormInitialValues } from '@typings/cds-financial';
import {
  GetRepresentativesQuery,
  GetRepresentativesQueryVariables,
  Representatives,
} from '@typings/operations';

export interface CDSFinancialFormProps {
  setRepsAvailable: Dispatch<SetStateAction<boolean>>;
}

const useFetchRepresentativesQuery = () =>
  useLazyQuery<GetRepresentativesQuery, GetRepresentativesQueryVariables>(
    fetchRepresentativesQuery
  );

const CDSFinancialForm: React.FC<CDSFinancialFormProps> = ({
  setRepsAvailable,
}) => {
  const { t } = useTranslation();
  const [showCards, setShowCards] = useState(false);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [fetchAllDistricts, { data }] = useFetchAllDistricts();
  const [
    fetchRepresentatives,
    { data: fetchedRepresentatives, loading },
  ] = useFetchRepresentativesQuery();
  const districtDropdownRef = useRef<any>(null);
  const initialValues: CDSFormInitialValues = {
    district: '',
  };
  useEffect(() => {
    fetchAllDistricts({
      variables: {
        status: 'active',
      },
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedDistrict)) {
      fetchRepresentatives({
        variables: {
          districtId: selectedDistrict,
        },
      });
    }
  }, [selectedDistrict]);

  const handleFormChange = (e: FormEvent) => {
    e.preventDefault();
    setShowCards(false);
    setRepsAvailable(false);
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(
        values,
        { setSubmitting }: FormikHelpers<CDSFormInitialValues>
      ) => {
        setShowCards(false);
        setRepsAvailable(false);
        setSubmitting(false);
        if (!isEmpty(values?.district)) {
          setSelectedDistrict(values?.district);
          setShowCards(true);
        }
      }}
      onReset={() => {
        districtDropdownRef.current?.clearValue();
        setShowCards(false);
        setRepsAvailable(false);
      }}
    >
      {({
        values,
        handleSubmit,
        resetForm,
      }: FormikProps<CDSFormInitialValues>) => {
        return (
          <Form
            onSubmit={handleSubmit}
            onChange={handleFormChange}
            id="cds-financial-form"
          >
            <Select
              name="district"
              label={t(
                'cds-financial.select-district-placeholder',
                'Select your district'
              )}
              placeholder={t(
                'cds-financial.select-district-placeholder',
                'Select your district'
              )}
              options={
                data?.districts
                  ?.map(district => ({
                    label: String(district.riDistrictId),
                    value: String(district.riDistrictId),
                  }))
                  .sort(
                    ({ value: firstDistrict }, { value: secondDistrict }) =>
                      Number(firstDistrict) - Number(secondDistrict)
                  ) || []
              }
              clearable
              searchable
              className="cds-card-select"
              dropdownRef={districtDropdownRef}
              menuItemHeight="89px"
              onChange={() => {
                setRepsAvailable(false);
              }}
            />
            <section className="flex-1 mt-4 button-section">
              <Button
                variant="primary"
                disabled={false}
                clickHandler={handleSubmit}
                className="mr-6"
              >
                {t('cds-financial.select', 'Select')}
              </Button>
              {!isEmpty(values?.district) && (
                <Button size="md" variant="text" clickHandler={resetForm}>
                  {t('cds-financial.reset', 'Reset')}
                </Button>
              )}
            </section>
            {loading && <Loading />}
            {showCards &&
              (fetchedRepresentatives?.representatives as Representatives[])?.map(
                representative => {
                  if (values?.district === representative.district) {
                    if (!isEmpty(representative.representativeInfo)) {
                      setRepsAvailable(true);
                    }
                    return (
                      <CDSFinancialCard
                        {...representative}
                        key={representative.id}
                      />
                    );
                  }
                  return null;
                }
              )}
          </Form>
        );
      }}
    </Formik>
  );
};
export default CDSFinancialForm;
